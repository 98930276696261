@media only screen and (max-width: 960px) {

    .winery-img {
        display:block;
        margin:auto;
    }

    .winery-div .row-title {
        text-align: center;
    }

}

@media only screen and (max-width: 500px) {

    .agenda-div,
    .about-div,
    .sponsor-div,
    .contact-div,
    .winery-div {
        padding-top: 10px;
        padding-bottom: 20px;
        min-height: 0;
    }

    .header-blue,
    .header {
        font-size: 28px;
        letter-spacing: 5px;
        margin-bottom: 10px;
        margin-top: 8px;
    }

    .agenda-div {
        background-attachment: scroll;
    }
    
    .intro-section {
        background-image: 
        linear-gradient(
          rgba(0, 0, 0, 0.4),
          rgba(0, 0, 0, 0.4)
        ),
        url('./../images/wine-grapes.jpg');
        background-attachment: scroll;
      min-height: 0;
    }

    .arrow-container {
        bottom: 110px;
        display: none;
    }
    
    .down-arrow {
        font-size: 40px !important;
    }

    .main-header-div {
        padding-top: 120px;
        margin-bottom: 40px;
    }

    .landing-circle {
        width: 100%;
        background: none;
    
    }

    .banner {
        height: 80px;
        padding-bottom: 10px;
        padding-top: 10px;
    }
    
    .banner-text {
        font-size: 18px;
    }

    #button-type {
        padding: 10px 40px;
        font-size: 18px;
    }
    
    #button-type:hover {
        color: #00BFFF;
        background: white;
    }


    .about-div .time-info,
    .about-div .location-info {
        text-align: center;
    }

    .about-div .time-info p,
    .about-div .location-info p {
        font-size: 24px;
    }


    .about-div .event-info {
        width: 90%;
        padding: 20px 10px;
    }

    .about-div .event-info p {
        font-size: 16px;
    }

    .agenda-info {
        padding-bottom: 30px;
    }

    .agenda-info p:first-child {
        font-size: 30px;
    }

    #winery-table {
        width: 90%;
        margin: 0 auto;
        padding-top: 5px;
    }

    .img-div {
        text-align: center;
    }

    .table-row {
        border-top:#D3D3D3 solid 1px;
        padding: 5px;
    }
    
    .row-title {
        font-size: 24px;
        letter-spacing: 2px;
        text-align: center;
    }

    .contact-name {
        font-size: 20px;
        margin-bottom: 2px;
        margin-top: 10px;
    }
    
    .contact-email {
        font-size: 18px;
        margin-bottom: 10px;
        margin-top: 0;
    }

    .sponsor-logo {
        width: 275px;
        margin-bottom: 10px;
    }

    .sponsor-text {
        display: none;
    }
    
}