@import url('https://fonts.googleapis.com/css?family=Montserrat|Roboto');

* {
    font-family: 'Roboto', sans-serif;
}

body {
    background-color: #00BFFF;
}

.intro-section {
      background-image: 
      linear-gradient(
        rgba(0, 0, 0, 0.25),
        rgba(0, 0, 0, 0.25)
      ),
      url('./../images/wine-grapes.jpg');

    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    min-height: 600px;
    height: calc(100vh - 100px);
    width: 100%;
}

.header-blue,
.header {
    margin: 0;
    font-size: 40px;
    letter-spacing: 7px;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
}

.header {
    color: white;
    text-shadow: 1px 1px 1px rgba(96, 94, 94, 0.25);
}

.header-blue {
    color:#00BFFF;
    text-shadow: 1px 1px 1px white;
}

.main-header-div {
    text-align: center;
    padding-top: 80px;
    margin-bottom: 40px;
    background: transparent;
}

.logo {
    color:white;
    margin-top: 0;
    margin-bottom: 5px;
    font-size: 60px;
    text-transform: uppercase;
    letter-spacing: 10px;
}

.landing-date {
    font-size: 20px;
    color: white;
    letter-spacing: 5px;
}

.landing-location {
    font-size: 18px;
    color: white;
    letter-spacing: 5px;
    text-transform: uppercase;
}

.landing-circle {
    width: 400px;
    background: rgba(0, 191, 255, 0.6);

}


.sponsor-div,
.contact-div,
.winery-div {
    width: 100%;
    padding-top: 40px;
    padding-bottom: 40px;
    min-height: 300px;
    margin: 0 auto;
}

.agenda-div {
    background-image: 
      linear-gradient(
        rgba(0, 191, 255, 0.75),
        rgba(0, 191, 255, 0.75)
      ),
      url('./../images/wine-grapes.jpg');
    

    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: cover;
    width: 100%;
}


.sponsor-div,
.winery-div {
    background: #f9f9f9;
}

.contact-div {
    background: #00BFFF;
}

.img-div,
.winery-img {
    max-width: 400px;
}

.winery-img {
    padding: 20px;
    width: 80%;
    /* margin-block-start: 28px; */
}

.img-div {
    height: 100%;
    display: flex;
    align-items: center;
    margin: 0 auto;
}

#winery-table {
    width: 1024px;
    margin: 0 auto;
    padding-top: 40px;
}

.table-row {
    border-top:#D3D3D3 solid 1px;
    padding: 10px;
}

.row-title {
    text-transform: capitalize;
    font-size: 28px;
    font-weight: 600;
}

.description {
    font-size: 14px;
    letter-spacing: 1px;
}

.sponsor-header {
    color:#00BFFF;
    letter-spacing: 4px;
    margin-bottom: 20px;
    text-align: center;
}

.sponsor-text {
    text-align: center;
    font-size: 18px;
}

.sponsor-text a:visited,
.sponsor-text a {
    color: #605E5E;
    text-decoration: none;
}

.sponsor-text a:hover {
    color: #00BFFF;
}


.contact-name {
    text-align: center;
    font-size: 24px;
    color: white;
}

.contact-email {
    text-align: center;
    font-size: 18px;
}

.contact-email a:visited,
.contact-email a {
    color: #605E5E;
    text-decoration: none;
}

.contact-email a:hover {
    color: white;
}

.sponsor-logo {
    width: 350px;
}

.sponsor-img-link {
    text-align: center;
}

#button-type {
    padding: 10px 60px;
    height: 50;
    font-size: 22px;
    letter-spacing: 2px;
    color: white;
    border: white solid 1px;
    background: transparent;
}

#button-type:hover {
    color: #00BFFF;
    background: white;
}

.agenda-div,
.about-div {
    width: 100%;
    text-align: 0 auto;
    padding-top: 40px;
    padding-bottom: 40px;
}

.about-div {
    background-color: #f9f9f9
}

.agenda-div h4,
.about-div h4 {
    color: #605E5E;
    font-size: 14px;
    text-transform: uppercase;
}

.about-div h1,
.agenda-div h4 {
    text-align: center;
}

.agenda-info {
    padding-bottom: 70px;
}

.agenda-info:nth-child(4) {
    padding-bottom: 0;
}


.agenda-info p {
    text-align: center;
    margin: 0;
}

.agenda-info p:first-child {
    font-size: 40px;
    font-weight: bold;
    text-transform: uppercase;
    color: white;
    text-shadow: 1px 1px 1px rgba(96, 94, 94, 0.25);
}

.agenda-info p:nth-child(2) {
    font-size: 16px;
    text-transform: uppercase;
}

.about-div .time-info,
.about-div .location-info {
    text-align: center;
    width: 50%;
}

.about-div .time-info p,
.about-div .location-info p {
    text-transform: uppercase;
    font-size: 32px;
    color: #605E5E;
    margin: 0;
}

.about-div .event-info {
    width: 70%;
    margin: 0 auto;
    text-align: center;
    padding: 40px 10px;
}

.about-div .event-info p {
    color: #605E5E;
    font-size: 18px;
}

.arrow-container {
    text-align: center;
    margin: 0 auto;
    position: absolute;
    bottom: 130px;
    width: 100%;
}

.down-arrow {
    color: white;
    font-size: 60px !important;
}

.banner {
    background:#00BFFF;
    width: 100%;
    text-align: center;
    height: 80px;
    padding-bottom: 10px;
    padding-top: 10px;
}

.banner-text {
    font-size: 20px;
    color: white;
    margin-top: 0px;
    margin-bottom: 10px;
}

.social-bar {
    width: 100%;
    text-align: center;
    margin-top: 20px;
}

.social-bar a {
    color: white;
    margin: 0px 30px;
    text-align: center;
}

.social-bar a:hover {
    color: #605E5E;
}

.social-bar a:visited {
    color: white;
}

.copyright {
    color: #00BFFF;
    text-align: center;
    font-size: 12px;
    position: relative;
    bottom: -15px;
    width: 100%;
}